import request from "@utils/request";

/**
 * 用户登录
 * @param data object 用户账号密码
 */
export function login(data) {
  return request.post("/login", data, { login: false });
}

/**
 * 退出登录
 */
export function logout() {
  return request.get("/logout", "", { login: false });
}

/**
 * 用户注册
 * @param data object 
 */
export function register(data) {
  return request.post("/register", data, { login: false });
}

/**
 * 单位用户注册
 * @param data object 
 */
export function unitRegister(data) {
  return request.post("/unitRegister", data, { login: false });
}

/**
 * 用户手机号修改密码
 * @param data object 
 */
export function registerReset(data) {
  return request.post("/register/reset", data, { login: true });
}

/*
 * 个人中心
 * */
export function getUser() {
  return request.get("/user", "", { login: true });
}

/*
 * 用户信息
 * */
export function getUserInfo() {
  return request.get("/userinfo");
}
/*
 * 修改用户信息
 * */
export function reset(data) {
  return request.post("/reset", data, { login: true });
}

/* 个人中心课程 */
export function getempowerCourse() {
  return request.get("empowerCourse", '', { login: true });
}


/* 培训课程报名 */
export function trainSigup(data) {
  return request.post("trainSignupPc", data, { login: false });
}

/* 获取培训课程报名列表 */
export function getSignups() {
  return request.get("signups", '', { login: true });
}

/* 培训课程报名 */
export function toPay(data) {
  return request.post("toPay", data, { login: true });
}

/* 订单支付状态 */
export function payStatus(data) {
  return request.post("payStatus", data, { login: true });
}

/* 订单列表 */
export function getOrders() {
  return request.post("orders", '', { login: true });
}

/* 我的考试列表数据 */
export function getUserExamPapers() {
  return request.get("userExamPapers", '', { login: true });
}

/* 查看我的考试详情 */
export function getScanUserExamPaper(data) {
  return request.get("scanUserExamPaper", data, { login: true });
}

/* 账号激活 */
export function activation(data) {
  return request.post("activation", data, { login: false });
}

